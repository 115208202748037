/*
* Neon Fabtik
*/

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&family=Montserrat:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Almarai&family=Amatic+SC&family=Bad+Script&family=Comic+Neue:wght@300&family=Dancing+Script&family=Delius+Unicase&family=Just+Another+Hand&family=League+Script&family=Montserrat&family=Open+Sans&family=Oswald&family=Pacifico&family=Patrick+Hand&family=Roboto&family=Sacramento&family=Sriracha&family=Syncopate&display=swap');

/* @font-face {
    font-family: Amanda;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125673/Amanda_a042ff2b21);
}

@font-face {
    font-family: Bauhaus;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125679/Bauhaus_ee68406a48);
}

@font-face {
    font-family: DolceVita;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125690/Dolce_Vita_480a7d13f);
}

@font-face {
    font-family: Gruenewald;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125706/Gruenewald_c9b37fe098);
}

@font-face {
    font-family: Kiona;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125715/Kiona_141b02d43f);
}

@font-face {
    font-family: LoveNote;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125747/Love_Note_705acfc797);
}

@font-face {
    font-family: MarqueeMoon;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125766/Northwell_2e133a465c);
}

@font-face {
    font-family: Outline;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125731/Outline_36f8e591d8);
}

@font-face {
    font-family: Roboto;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125773/Roboto_143000783d)
} */

/* @font-face {
    font-family: LoveNote;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/LoveNote.ttf?alt=media&token=e2e8d498-59e4-4107-b5f1-d03ef735887c);
}

@font-face {
    font-family: Bauhaus;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Bauhaus.ttf?alt=media&token=a5f9fc06-37b7-4bc9-8b7a-2d78f7884f64);
}

@font-face {
    font-family: Allura;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Allura.ttf?alt=media&token=7aed31e2-cc2f-4e02-826a-b3af89255319);
}

@font-face {
    font-family: AlexBrush;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/AlexBrush.ttf?alt=media&token=a42d52ba-a96e-4a07-a71c-22a391686a77);
}

@font-face {
    font-family: Roboto;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Roboto.ttf?alt=media&token=09aa0164-1262-4bf0-8686-8bb717c72f2a);
}

@font-face {
    font-family: Gruenewald;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Gruenewald.ttf?alt=media&token=45b7d16d-c14a-4de5-9c99-81e52d83a168);
}

@font-face {
    font-family: KBWriters;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/KBWriters.ttf?alt=media&token=25e3894d-4d22-4210-a1e2-56e3d0b1eee4);
}

@font-face {
    font-family: Darcey;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Darcey.ttf?alt=media&token=6f34d18f-7da2-48fd-99f2-17d9f2111b47);
}

@font-face {
    font-family: Outline;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Outline.ttf?alt=media&token=e3aebca1-0368-4b60-802b-bf81ed2aae36);
}

@font-face {
    font-family: DolceVita;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/DolceVita.ttf?alt=media&token=3f1cd197-1ab1-4051-b144-20fe43048b53);
}
@font-face {
    font-family: Kiona;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Kiona.ttf?alt=media&token=1b27353c-aafc-40c7-8793-c9d8f887d4cb);
}

@font-face {
    font-family: MarqueeMoon;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/MarqueeMoon.ttf?alt=media&token=d4235977-9d17-41fa-82f3-f37072d80bfd);
}

@font-face {
    font-family: Budhayanti;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Budhayanti.ttf?alt=media&token=40db684b-7ab8-4229-b6dc-6edb40f1eaf8);
}

@font-face {
    font-family: Amanda;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Amanda.ttf?alt=media&token=3887bd64-67aa-41ad-abe4-38ad988a78ac);
}

@font-face {
    font-family: Hamillton;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Hamillton.ttf?alt=media&token=47c8e02d-dbd2-43cc-bffc-2359af1c0e1f);
}

@font-face {
    font-family: Ahsley;
    src: url(https://firebasestorage.googleapis.com/v0/b/web-app-front-end.appspot.com/o/Ahsley.ttf?alt=media&token=098d4258-6090-4746-af96-a4b2fc4f827e);
} */


@font-face {
    font-family: LoveNote;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125747/Love_Note_705acfc797);
}

@font-face {
    font-family: Bauhaus;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125679/Bauhaus_ee68406a48);
}

@font-face {
    font-family: Allura;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125681/Allura_9868da625c);
}

@font-face {
    font-family: AlexBrush;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125675/Alex_Brush_8694b3ceba);
}

@font-face {
    font-family: Roboto;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125773/Roboto_143000783d);
}

@font-face {
    font-family: Gruenewald;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125706/Gruenewald_c9b37fe098);
}

@font-face {
    font-family: KBWriters;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125694/KB_Writers_a1fd8e9d3d);
}

@font-face {
    font-family: Darcey;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125694/Darcey_6fe1c46043);
}

@font-face {
    font-family: Outline;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125731/Outline_36f8e591d8);
}

@font-face {
    font-family: DolceVita;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125690/Dolce_Vita_480a7d13f7);
}
@font-face {
    font-family: Kiona;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125715/Kiona_141b02d43f);
}

@font-face {
    font-family: MarqueeMoon;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125725/Marquee_Moon_36e1e083f4);
}

@font-face {
    font-family: Budhayanti;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125706/Budhayanti_34e235a201);
}

@font-face {
    font-family: Amanda;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125673/Amanda_a042ff2b21);
}

@font-face {
    font-family: Hamillton;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125696/Hamillton_1e79ad35d2);
}

@font-face {
    font-family: Ahsley;
    src: url(https://res.cloudinary.com/dnsjrweez/raw/upload/v1600125680/Ahsley_15152ea86e);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style-type: none;
}

body {
    overflow-x: hidden;
}

.wraper {
    width: 100%;
    height: 100%;
}


/* Constant */

@import "./constant";

/* Sidebar */

@import "./component/sidebar";

/* Accordion */

@import './component/accordion';

/* Header */

@import './component/header';

/* Footer */

@import './component/footer';

/* Card */

@import './component/card';

/* Button */

@import './component/button';

/* Model */

@import './component/model';

/* Form Elements */

@import './FormElement/formElement';

/* Carousel */

@import './component/carousel';

/* Landing page */

@import './pages/landingPage';

/* Spinner Loading */

@import './component/spinner';

/* Tabs */

@import './component/tab';

/* Skeleton */
@import './component/skeleton';