.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8AD;
}

.loader {
    left: 35%;
    top: 30%;
    z-index: 1000;
    position: absolute;
    img {
        width: 110px;
    }
}