.header-main {
    display: flex;
    justify-content: space-between;
    height: 56px;
    background-color: rgb(249, 250, 251);
    .logo-container {
        padding: 4px 24px;
    }
    .nav-main {
        display: flex;
        padding: 16px;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .languages {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 41.86px;
        .langauge-icon {
            margin: 8px;
        }
    }
}