.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background-color: rgb(249, 250, 251);
    .footer-text {
        @include fontMontserratRegular;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #003056;
    }
}