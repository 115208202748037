

.skeleton{
    margin: 10px auto;
    background-color: #eeeeee !important;
    
    &.toggle{
        width: 320px;
        height: 40px;
        border-radius: 50px;
        padding: 8px;

        
    }
    &.input{
    width: 320px;
    height: 80px;
    padding: 10px;
    
    }
    &.title{
        width: 160px;
        height: 23px;
        margin: 0;
    }
    &.accordion{
        width: 320px;
        height: 63px;
    }
    &.scrollbar{
        width: 320px;
        height: 120.76px;
    }
    &.text{
        width: 100%;
        height: 12px;
    }
    &.range{
        width: 320px;
        height: 30px;
    }
    &.button{
        width: 287px;
        height: 54px;
        border-radius: 30px;
    }
    &.view{
        width: 100%;
        //max-width: 1102px;
        height: 932px;
        background: #f2f2f2 !important;
    }
    &.svg{
        width: 42vw;
        height: 21vh;
        border-radius: 12px;
        background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px) !important;
        background-size: 600px !important;
        animation: shine-avatar 1.6s infinite linear;
    }
}

.skeleton-wrapper{
    position: relative;
    //overflow: hidden;
       &.light{
            background: #444444;
           .skeleton{
            background: #f2f2f2;
           }
           
       }
       &.dark{
          background: #444444;
          .skeleton {
              background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
              background-size: 600px;
              animation: shine-lines 1.6s infinite linear;
          }
    }
}


.skeleton-wrapper__element{
    

}
/* annimation effect */
.shimmer-wrapper{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite; 
}

.shimmer{
    width: 50%;
    height: 100%;
    background: rgba(255,255,255, 0.8);
    transform: skewX(-200deg);
}
@keyframes loading {
    0% { transform: translateX(-150%);}
    50% { transform: translateX(-60%);}
    100% { transform: translateX(150%);}
}


@keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 320px;
    }
  }
  @keyframes shine-avatar {
    0% {
      background-position: -32px;
    }
    40%, 100% {
      background-position: 42vw;
    }
  }