    .card-configuration {
        display: block;
    }
    
    .card {
        width: 327px;
        height: 199px;
        padding: 20px;
        position: absolute;
        top: 691px;
    }
    
    .price {
        display: flex;
        align-items: center;
        p {
            @include fontJosefinSansRegular;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            margin-right: 54px;
            color: $primary-color;
        }
        h1 {
            @include fontJosefinSansLight;
            font-size: 30px;
            line-height: 44px;
            text-align: center;
            color: #000000;
            font-weight: 500;
        }
    }
    
    .price-details {
        p {
            @include fontJosefinSansLight;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #626262;
        }
    }

    .extra-price{
        position: relative;
        left: 152px;
        p{
            @include fontJosefinSansLight;
            font-size: 10px;
            line-height: 16px;
            text-transform: capitalize;
            color: #626262;
        }
    }
    
    @media only screen and (max-width: 992px) {
        .card-configuration {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .card {
            width: 327px;
            height: 199px;
            padding: 20px;
            position: relative;
            top: 0;
        }
    }


    .main-wrapper-release_mb{

        .card {
            width: 327px;
            height: 199px;
            padding: 20px;
            position: relative;
            top: -163px;
            left: 29px;
        } 
    }