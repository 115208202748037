@mixin border {
    border: 2px solid #E5EAEE;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.34));
}

@mixin rangeBorder {
    border-radius: 50%;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 2px #FFFFFF;
    border: 1px solid $primary-color-opacity;
}

@mixin headLine {
    @include fontMontserratRegular;
    font-size: 20px;
    line-height: 23px;
    color: #003056;
}


/* Montserrat Font */

@mixin fontMontserratLight {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

@mixin fontMontserratRegular {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

@mixin fontMontserratBold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}


/* Josefin Sans Font */

@mixin fontJosefinSansLight {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
}

@mixin fontJosefinSansRegular {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
}

@mixin fontJosefinSansBold {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
}

@mixin AmandaFont {
    //font-family: Amanda;
}

$darkgrey: #626262;
$lightgrey: #E0E0E0;
$primary-color: #10A7DA;
$primary-color-opacity: #00A1D8;

/* Slider */

$height: 30px;
$thumb-height: 24px;
$track-height: 8px;
// colours
$lower-color: #05518B;
$thumb-color: #fff;
$thumb-hover-color: $lightgrey;
$upper-background: linear-gradient(to bottom, $lightgrey, $lightgrey) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;
@function webkit-slider-thumb-shadow($i: 1) {
    $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$lightgrey};
    @if $i==1 {
        @for $k from 2 through 1000 {
            $val: #{$val},
            webkit-slider-thumb-shadow($k);
        }
    }
    @return $val;
}