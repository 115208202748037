.tabs {
    display: flex;
    flex-direction: column;
}

.tab-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.tab-list {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .tab-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &>li {
        margin: 15px;
    }
}

.tab-list__line {
    display: block;
    height: 1px;
    width: 265px;
    border-width: 0;
    color: #CBCBCB;
    background-color: #CBCBCB;
    //margin: 0 auto;
}



// Tabs Top

.tabs-top {
    display: flex;
    flex-direction: row;
    position: relative;
}


.tab-content-top {
    background: #FFFFFF;
    box-shadow: 1px 0px 8px #D2D2D2;
    //width: 256px; 
    width: 263px;
    height: 690px;
}

.tb-configuration{
    .tab-content-top {
        background: #FFFFFF;
        box-shadow: 1px 0px 8px #d2d2d2;
        //width: 256px; 
        width: 263px;
        height: auto;
    }
    .tabs-top {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 380px; 
    }
}

.main-wrapper-release_mb{
    .tab-content-top {
       /*  background: #FFFFFF;
        box-shadow: 1px 0px 8px #D2D2D2;
        //width: 256px; 
        width: 263px; */
        height: 720px;
    }
}


.tab-list-top {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    background-color: #363636;
    position: absolute;
    right: 262px;
    top: 0px;
    width: 64px;
    .tab-list-item_top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 62px;
    }
    &>li {
        margin-bottom: 15px;
    }
    &>li:nth-child(4) {
        margin-bottom: 0px;
    }
}


.tab-list__line-top {
    display: block;
    height: 1px;
    width: 100%;
    border-width: 0;
    color: #CBCBCB;
    background-color: #CBCBCB;
    margin: 0 auto;
}


.tab-list-active_top{
    background-color: #FFFFFF;
    transition: all 0.5s;
    path{
        fill: #363636;
    }
}