/* Input Text Form */

.input-text-container {
    display: block;
    padding: 13px;
    margin-bottom: 0px;
    .input-text {
        font-size: 30px;
        /* width: 320px;
        height: 80px; */
        height: 79px;
        width: 233px;
        outline: none;
        @include border;
        padding: 10px;
    }
}

.input-text::-webkit-input-placeholder {
    font-size: 24px;
    line-height: 60px;
    color: rgba(0, 48, 86, 0.52);
    padding: 5px;
}

.input-text::-ms-input-placeholder {
    font-size: 24px;
    line-height: 60px;
    color: rgba(0, 48, 86, 0.52);
    padding: 5px;
}

.input-text:-ms-input-placeholder {
    font-size: 24px;
    line-height: 60px;
    color: rgba(0, 48, 86, 0.52);
    padding: 5px;
}

.input-text::-moz-placeholder {
    font-size: 24px;
    line-height: 60px;
    color: rgba(0, 48, 86, 0.52);
    padding: 5px;
}

.input-text:-moz-placeholder {
    font-size: 24px;
    line-height: 60px;
    color: rgba(0, 48, 86, 0.52);
    padding: 5px;
}

.input-text::placeholder {
    //@include AmandaFont;
    font-size: 24px;
    line-height: 60px;
    color: rgba(0, 48, 86, 0.52);
    padding: 5px;
}


/* Range Input Form */

.input-range-container {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    h1 {
        @include headLine;
    }
    .input-range-content {
        display: flex;
        margin-top: 31px;
        width: 256px;
        .range-input-value {
        padding: 5px;
        width: 57px;
        /* border: 2px solid #E5EAEE; */
        background: #FFFFFF;
        border: 3px solid #333333;
        box-sizing: border-box;
        box-shadow: 1px 2px 7px #cccccc;
        border-radius: 11px;
        }
    }
    .input-range {
        display: block;
        appearance: none;
        margin: 0;
        -webkit-appearance: none;
        width: 222px;
        border-radius: 20px;
        //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        outline: none;
        margin: 0 19px;
        overflow: hidden;
        //box-shadow: inset 0 0 5px grey;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    // Webkit
    .input-range::-webkit-slider-runnable-track {
        width: 100%;
        height: $height;
        background: $lower-background;
    }
    .input-range::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        border-radius: 100%;
        border: 1px solid $lower-color;
        top: 50%;
        margin-top: (-$thumb-height/2);
        box-shadow: webkit-slider-thumb-shadow();
        transition: background-color 150ms;
    }
    // Firefox
    .input-range::-moz-range-track,
    .input-range::-moz-range-progress {
        width: 100%;
        height: $height;
        background: $upper-background;
        border-radius: 20px;
    }
    .input-range::-moz-range-progress {
        background: $lower-background;
    }
    .input-range::-moz-range-thumb {
        appearance: none;
        margin: 0;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
    }
    // Internet Exploder
    .input-range::-ms-track {
        width: 100%;
        height: $height;
        border: 0;
        // color needed to hide track marks
        color: transparent;
        background: transparent;
    }
    .input-range::-ms-fill-lower {
        background: $lower-background;
    }
    .input-range::-ms-fill-upper {
        background: $upper-background;
    }
    .input-range::-ms-thumb {
        appearance: none;
        height: $thumb-height;
        width: $thumb-height;
        background: $thumb-color;
        border-radius: 100%;
        border: 0;
        transition: background-color 150ms;
        // IE Edge thinks it can support -webkit prefixes
        top: 0;
        margin: 0;
    }
    .input-range:hover,
    .input-range:focus {
        &::-webkit-slider-thumb {
            background-color: $thumb-hover-color;
        }
        &::-moz-range-thumb {
            background-color: $thumb-hover-color;
        }
        &::-ms-thumb {
            background-color: $thumb-hover-color;
        }
    }
    /*
.input-range {
    -webkit-appearance: none;
    width: 265px;
    height: 8px;
    border-radius: 20px;
    background-color: $lightgrey;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    outline: none;
    margin: 15.8px 19px;
}
*/
    /*
.input-range::-webkit-slider-thumb {
    appearance: none;
    background: #FFFFFF;
    width: 24px;
    height: 24px;
    @include rangeBorder;
    cursor: pointer;
}
.input-range::-moz-range-thumb {
    appearance: none;
    background: #FFFFFF;
    width: 24px;
    height: 24px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    //@include rangeBorder;
    cursor: pointer;
}
.input-range::-moz-range-progress {
    background: #05518B;
}
.input-range::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
}
*/
    .range-value, .size-title {
        @include fontMontserratRegular;
        font-size: 12px;
        line-height: 39px;
        text-align: center;
        padding: 8px;
        color: #000000 !important;
    }
    .size-title {
      font-weight: bold;
    }
}

.typeface-container {
    padding-bottom: 5px;
    width: 256px;
    h1 {
        @include headLine;
    }
}


/* Multi select Form */

.multiselect-scrollbar {
    display: block;
    overflow-y: scroll;
    margin-top: 23px;
}

.multiselect-scrollbar::-webkit-scrollbar {
    display: block;
    width: 10px;
}


/* Track */

.multiselect-scrollbar::-webkit-scrollbar-track {
    display: block;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

.multiselect-scrollbar::-webkit-scrollbar-thumb {
    display: block;
    background: #05518B;
    border-radius: 20px;
}


/* Handle on hover */

.multiselect-scrollbar::-webkit-scrollbar-thumb:hover {
    display: block;
    background: #05518B;
}

.multiselect-container {
    display: inline-flex;
    flex-wrap: wrap;
    height: 90px;
    justify-content: start;
    .multiselect {
        margin: 6px;
        /* width: 90px;
        height: 48px; */
        height: 34px;
        width: 64px;
        border: 1px solid $lightgrey;
        border-radius: 6px;
        background-color: #FFFFFF;
        cursor: pointer;
        position: relative;
        &:hover {
            //border: 1px solid $primary-color;
            background-color: $lightgrey;
        }
        .custom-multiselect-font {
            position: relative;
            input {
                display: none;
            }
        }
        .custom-multiselect-font .text-multiselect-font {
            position: absolute;
            //top: 13px;
            //left: 11px;
            //transition: .2s;
            cursor: pointer;
            font-size: .9em;
            text-align: center;
            top: 12px;
            left: 5px;
        }
        .custom-multiselect-font input:checked+.text-multiselect-font {
            background-color: $lightgrey;
            /* width: 90px;
            height: 48px; */
            height: 34px;
            width: 64px;
            //border: 1px solid $primary-color;
            border-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            p {
                position: absolute;
                top: 13px;
                left: 11px;
                //transition: .2s;
                cursor: pointer;
                font-size: .9em;
                text-align: center;
            }
        }
    }
}


/* Installation Option */

.installation-container {
    display: inline-flex;
    flex-wrap: wrap;
    height: 120.68px;
    justify-content: start;
    h1 {
        @include headLine;
        padding-bottom: 11px;
    }
    .multiselect-installation {
        margin: 5px;
        width: 109px;
        height: 48px;
        border: 1px solid $lightgrey;
        border-radius: 6px;
        background-color: #FFFFFF;
        cursor: pointer;
        &:hover {
            border: 1px solid $primary-color;
            //background-color: $lightgrey;
        }
        .custom-multiselect-installation {
            position: relative;
            input {
                display: none;
            }
        }
        .custom-multiselect-installation .text-multiselect-installation {
            font-family: Roboto;
            font-size: 18px;
            line-height: 21.09px;
            position: relative;
            text-align: center;
            top: 12px;
            cursor: pointer;
            p{
                font-size: 14px;
                font-weight: 800;
            }
        }
        .custom-multiselect-installation input:checked+.text-multiselect-installation {
            width: 109px;
            height: 48px;
            border-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            p {
                background-color: $primary-color;
                height: 48px;
                border: none;
                border-radius: 6px;
                padding-top: 12px;
                font-size: 14px;
                font-weight: 800;
            }
        }
    }
}


/* Switch: Toggle Form */

.switch-toggle {
    position: relative;
    /* width: 320px;
    height: 40px; */
    height: 27px;
    width: 220px;
    background-color: $lightgrey;
    -webkit-appearance: none;
    border-radius: 50px;
    padding: 8px;
    outline: none;
    transition: .4s;
    cursor: pointer;
}

.switch-toggle:checked {
    background-color: $lightgrey;
}

.switch-toggle::before {
    content: "";
    position: absolute;
    left: 3.91px;
    /* width: 160px;
    height: 33.34px; */
    height: 22.5px;
    width: 107.65px;
    background-color: $primary-color;
    transition: .4s;
    border-radius: 50px;
    top: 2.33px;
    //top: 3.33px;
    //z-index: 2;
    transition: .4s;
}

.switch-toggle:checked::before {
    //left: 150.19px;
    left: 108.67px;
}

.switch-configuration {
    display: block;
}

.switch-container {
    position: relative;
    display: inline;
    top: 13px;
    left: 13px;
    label {
        @include fontMontserratBold;
        position: absolute;
        /* font-size: 14px;
        line-height: 17px; */
        font-size: 10px;
        line-height: 5px;
        text-align: center;
        color: #FFFFFF;
        pointer-events: none;
        text-transform: uppercase;
    }
    .onbtn {
        left: 30px;
        bottom: 15px;
    }
    .offbtn {
        right: 30px;
        bottom: 15px;
    }
}

.backgound-plexes {
    .switch-container {
        .offbtn {
            right: 62px;
            bottom: 15px;
        }
    }
}


/* Backgound Color */

.background-color-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 0;
    .background-colors {
        margin: 2px;
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #eee;
        border-radius: 6px;
        background-color: $primary-color;
        cursor: pointer;
    }
    .custom-background-color input {
        display: none;
    }
    .background-checkmark-btn {
        margin-top: 14px;
        margin-left: 14px;
        transition: .2s;
        cursor: pointer;
        fill-opacity: 0;
    }
    .custom-background-color input:checked+.btn-background-color>svg {
        fill-opacity: 1;
    }
}

.backgound-plexes {
    .switch-container {
        display: inline;
        top: 0;
        left: 0;
    }
}

.background-plexes-content {
    position: relative;
    display: block;
    .background-colors {
        margin: 8px;
        box-shadow: 0px 4px 4px #C4C4C4;
        border-radius: 8px;
    }
    h1 {
        padding-top: 20px;
        padding-bottom: 11px;
        @include headLine;
    }
    .background-color-title {
        @include fontMontserratRegular;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 12px;
        text-align: center;
        top: 16px;
        /* left: 6px; */
        color: gray;
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }
    .custom-background-color input:checked+.background-color-title {
        opacity: 1;
        cursor: pointer;
    }
}

.background-plexes-content.active {
    display: none;
}

.background-pvc-content {
    display: block;
    h1 {
        padding-top: 20px;
        padding-bottom: 11px;
        @include headLine;
    }
}

.background-pvc-content.active {
    display: none;
}

@media only screen and (max-width: 992px) {
    .input-text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 26px;
        padding: 0;
    }
    .input-range-container {
        margin-top: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .switch-configuration {
        display: flex;
        justify-content: center;
    }
    .multiselect-container {
        justify-content: center;
    }
    .multiselect-scrollbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .multiselect-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Track */
    .multiselect-scrollbar::-webkit-scrollbar-track {
        display: none;
    }
    /* Handle */
    .multiselect-scrollbar::-webkit-scrollbar-thumb {
        display: none;
    }
    /* Handle on hover */
    .multiselect-scrollbar::-webkit-scrollbar-thumb:hover {
        display: none;
    }
    .background-color-container {
        justify-content: center;
    }
    .installation-container {
        display: block;
    }
    .background-color-container {
        margin-bottom: 26px;
    }
    .switch-container {
        left: 0px;
    }
    .typeface-container {
        padding-bottom: 26px;
    }
}

@media only screen and (max-width: 417px) {
    .multiselect-scrollbar {
        display: block;
    }
    .multiselect-scrollbar::-webkit-scrollbar {
        display: block;
    }
    /* Track */
    .multiselect-scrollbar::-webkit-scrollbar-track {
        display: block;
    }
    /* Handle */
    .multiselect-scrollbar::-webkit-scrollbar-thumb {
        display: block;
    }
    /* Handle on hover */
    .multiselect-scrollbar::-webkit-scrollbar-thumb:hover {
        display: block;
    }
}

#text-title{
    @include headLine;  
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-left: 12px;
}


.range-sizing{
    display: flex;
    justify-content: space-around;
    margin-top: -9px;
}

.wrapper-title{
    @include fontMontserratRegular; 
    font-size: 28px;
    line-height: 33px;
    /* or 118% */
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #626262;
}

.wrapper-title_mb{
    @include fontMontserratRegular; 
    font-size: 18px;
    line-height: 33px;
    /* or 118% */
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #626262;
}

.wrapper-text{
    @include fontMontserratLight; 
    font-size: 24px;
    line-height: 33px;
    /* or 137% */
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #626262;
}

.wrapper-paragraph{
    margin-top: 25px;
}
.wrapper-title-nestead{
    color: $primary-color;
}


.loading-section{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 auto; */
    width: 200px;
    @include fontMontserratLight;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #626262;
}