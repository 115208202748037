.main-wrapper {
    .sidebar-wrapper {
        #sidebar {
            display: block;
            min-height: 100%;
            height: 100%;
            width: 360px;
            position: absolute;
            right: 0px;
            background-color: white;
            transition: all 0.3s ease;
            z-index: 1;
            .sidebar-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: -27px;
                top: 4.21px;
                width: 56px;
                height: 57.23px;
                border-radius: 50%;
                background-color: $primary-color-opacity;
                filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.36));
                cursor: pointer;
            }
            .sidebar-title {
                display: block;
                padding-left: 45px;
                h1 {
                    @include fontJosefinSansBold;
                    font-size: 32px;
                    line-height: 33px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: $darkgrey;
                }
                span {
                    color: $primary-color;
                }
            }
            .configuration {
                width: 100%;
                max-width: 320px;
                margin: 0 auto;
                margin-top: 8px;
            }
        }
        /*
        .sidebar-menu.open {
            transform: translateX(0);
        }
        .sidebar-menu.close {
            transform: translateX(360px);
        }
         
        .sidebar-btn.active {
            position: absolute;
            left: -64.18px !important;
        }
        */
    }
}

@media only screen and (max-width: 992px) {
    .main-wrapper {
        .sidebar-wrapper {
            #sidebar {
                display: block;
                min-height: 100%;
                height: 100%;
                width: 100%;
                position: absolute;
                right: 0px;
                background-color: white;
                transition: all 0.3s ease;
                z-index: 1;
                .sidebar-btn {
                    display: none
                }
                .sidebar-title {
                    padding-left: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h1 {
                        @include fontJosefinSansBold;
                        font-size: 32px;
                        line-height: 33px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: $darkgrey;
                    }
                    span {
                        color: $primary-color;
                    }
                }
                .configuration {
                    width: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                    margin-top: 8px;
                }
            }
            /*
            .sidebar-menu.open {
                transform: translateX(0);
            }
            .sidebar-menu.close {
                transform: translateX(360px);
            }
             
            .sidebar-btn.active {
                position: absolute;
                left: -64.18px !important;
            }
            */
        }
    }
}