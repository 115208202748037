.option-carousel-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 335px;
    height: 76px;
    background: #FAFAF8;
    .option-carousel-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        .slides {
            width: 95.67px;
            height: 51px;
            transition: .5s;
        }
        &>div {
            margin: 10px;
        }
        .slides-title {
            @include fontMontserratRegular;
            font-size: 12px;
            line-height: 25px;
            //position: absolute;
           /*  bottom: 8px;
            left: 16px; */
            bottom: 2px;
            left: 107px;
            position: absolute;
            width: 90px;
        }
    }
    .option-carousel-control {
        display: flex;
        justify-content: center;
        align-items: center;
        .option-carousel-color {
            width: 20px;
            height: 20px;
            background: #000000;
            border-radius: 2px;
            border: 2px solid #ffffff;
        }
        .option-carousel-buttons {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            &>svg {
                margin: 20px;
            }
        }
    }
    .carousel-container {
        position: relative;
    }
}


/**********/


/*
.slider {
    position: relative;
    width: 800px;
    margin: 0 auto;
    height: 500px;
    overflow: hidden;
    white-space: nowrap;
}

.slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
}

.arrow {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    border-radius: 50%;
    cursor: pointer;
    transition: transform ease-in .1s;
}

.nextArrow {
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 999;
    color: #fff;
}

.backArrow {
    position: absolute;
    top: 50%;
    left: 25px;
    z-index: 999;
    color: #fff;
}

.fa-arrow-right:before,
.fa-arrow-left:before {
    color: #222
}

#vids {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#vids a:first-of-type {
    margin-bottom: 5px;
}

*/

.carousel {
    /* align-items: center;
    display: flex; */
    margin: 2rem auto;
    overflow: hidden;
    position: relative;
    //height: 51px;
    &__images {
        display: flex;
        transform: translateX(0);
        //transition: transform 0.25s;
        width: 96px;
        transition: background-image 500ms ease-in-out;
        img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
        }
        p {
            @include fontMontserratRegular;
            font-size: 12px;
            line-height: 25px;
            bottom: 8px;
            left: 16px;
        }
    }
    &__button {
        background: teal;
        border: 0;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: bold;
        height: 3rem;
        opacity: 0.25;
        position: absolute;
        transition: opacity 0.25s;
        width: 3rem;
        z-index: 1;
        &.previous {
            left: 5px;
        }
        &.next {
            right: 5px;
        }
        &:hover {
            opacity: 0.5;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}