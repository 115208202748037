.container-btn {
    position: relative;
    z-index: 1;
    padding: 10px 63px;
}

.btn-cart {
    background: $primary-color;
    border-radius: 30px;
    width: 170px;
    height: 45px;
    outline: none;
    border: none;
    position: absolute;
    cursor: pointer;
    .btn-text {
        @include fontMontserratLight;
        font-size: 10px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 40px 10px;
    }
}

.cart-count {
    left: -9px;
    display: flex;
    width: 110px;
    height: 45px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 50px;
    position: absolute;
    top: 10px;
    z-index: -1;
    .btn-quantity {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 43px;
        top: 6px;
    }
    .btn-quantity>svg {
        margin-bottom: 12px;
    }
    .count {
        @include fontJosefinSansRegular;
        top: 12px;
        position: absolute;
        left: 18px;
        font-size: 24px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #626262;
    }
    .upbtn {
        cursor: pointer;
    }
    .downbtn {
        cursor: pointer;
    }
}