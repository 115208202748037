.model-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .model-close {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .model-box {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: auto;
        //height: 70%;
        //width: 60%;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        z-index: 101;
        padding: 40px;
        width: 483.3px;
        height: 337px;
        background: #FFFFFF;
        border-radius: 10.1174px;
        h1 {
            @include fontJosefinSansRegular;
            text-align: center;
            padding-top: 116px;
        }
    }
}