.accordion-section {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #CBCBCB;
}

.accordion-btn {
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: 19px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    &:hover,
    .active {
        background-color: #fff;
    }
}

.accordion-title {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        margin-right: 22px;
    }
    p {
        @include fontMontserratRegular;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
    }
}

.accordion-icon {
    margin-left: auto;
    transition: transform 0.6s ease;
}

.rotate {
    transform: rotate(180deg);
}

.accordion-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    //overflow: hidden;
    transition: max-height 0.6s ease;
}