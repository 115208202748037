    .main-wrapper {
        overflow-x: hidden;
        .container-wrapper {
            width: calc(100% - 360px);
            margin-right: 360px;
            transition: all .3s ease;
            .viewbox-container {
                height: 100%;
                background-color: #000;
                position: relative;
                .viewbox-text {
                    @include AmandaFont;
                    position: absolute;
                    //top: calc(50% + (36px + 56px));
                    top: calc(20% + (36px + 56px));
                    left: 25%;
                    // padding: 1rem;
                    //border: 3px solid black;
                    // font-size: 6.94vw;
                    display: flex;
                    align-items: center;
                    // text-align: center;
                    justify-content: center;
                    color: #FFFFFF;
                    // height: 110px;
                    // width: calc(100% - 360px);
                    z-index: 1;
                }
                .line-sun {
                    text-shadow: 0px 2px 13px #F2D03B;
                }
                .line-moon {
                    text-shadow: 0px 2px 13px #FFFFFF;
                }
                img {
                    //width: 100%;
                }
                .theme {
                    position: absolute;
                    //top: 70px;
                    left: 24px;
                    z-index: 1;
                    display: flex;
                    &>svg {
                        margin: 18px;
                        cursor: pointer;
                    }
                }
                .btn-theme {
                    position: absolute;
                    top: 9px;
                    left: 185px;
                    z-index: 1;
                    display: flex;
                    .btn {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #FFFFFF;
                        filter: drop-shadow(0px 0px 4px #FFFFFF);
                        opacity: 0.4;
                        font-size: 10px;
                        line-height: 12px;
                        text-align: center;
                        color: #000000;
                        outline: none;
                        cursor: pointer;
                    }
                    &>button {
                        margin: 7px
                    }
                    .btn-on {
                        background: #F2D03B;
                        opacity: 0.8;
                    }
                    .btn-off {
                        background: #FFFFFF;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    
    .main-wrapper.open {
        .sidebar-wrapper {
            .sidebar-menu {
                //transform: translateX(0);
                transform: scale(1, 1)
            }
        }
    }
    
    .main-wrapper.close {
        overflow: hidden;
        .sidebar-wrapper {
            .sidebar-menu {
                transform: translateX(360px);
                transition: transform 150ms ease-in-out;
                //transform-origin: 0 0;
                transform-origin: top right;
                //transform: scale(0, 1);
                .sidebar-btn.active {
                    position: absolute;
                    left: -64.18px !important;
                    z-index: 0;
                }
            }
        }
        .container-wrapper {
            width: 100%;
            margin-right: 0;
            transition: all .3s ease;
            .viewbox-text {
                @include AmandaFont;
                position: absolute;
                //top: calc(50% + (36px + 56px));
                top: calc(20% + (36px + 56px));
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                color: #FFFFFF;
                // height: 110px;
                // width: 100%;
                z-index: 1;
            }
            .line-sun>svg {
                text-shadow: 0px 2px 13px #F2D03B;
            }
            .line-moon {
                text-shadow: 0px 2px 13px #FFFFFF;
            }
        }
    }
    
    #overlay {
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        //: 2;
    }
    
    #svg-holder {
        padding: 10px;
    }
    
    .cursor-move {
        cursor: move;
    }
    
    .svg-width {
        @include fontMontserratRegular;
        position: absolute;
        bottom: -22px;
        border-top: 2px solid aliceblue;
        text-shadow: none;
        width: 100%;
        text-align: center;
    }
    
    .svg-height {
        @include fontMontserratRegular;
        position: absolute;
        right: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 2px solid aliceblue;
        text-shadow: none;
        margin-right: 5px;
        padding-right: 5px;
        .height-text {
            transform: rotate(-90deg) translateY(10px);
        }
    }
    
    .slideshow-container {
        position: relative;
        width: 100%;
        height: 557px;
        display: flex;
        justify-content: center;
        align-items: center;
        #slider {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .navigation {
            position: absolute;
            bottom: -130px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                list-style: none;
                cursor: pointer;
                margin: 10px;
                img {
                    width: 7vw;
                    height: 14vh;
                    border: 3px solid $primary-color;
                    border-radius: 6px;
                }
            }
        }
    }
    
    @media only screen and (max-width: 1200px) {
        .main-wrapper {
            .container-wrapper {
                width: 100%;
                margin-right: 0px;
            }
        }
        .slideshow-container {
            #slider {
                height: auto !important;
            }
            .navigation {
                li {
                    margin: 6px;
                    img {
                        width: 12vw !important;
                        height: 7vh !important;
                        border: 3px solid $primary-color;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 600px) {
        .slideshow-container {
            height: 255px;
            //width: 334px;
            #slider {
                //height: 430.1px !important;
                height: 255px !important;
                //width: 334px !important;
            }
            .navigation {
                bottom: -90px;
                li {
                    margin: 6px;
                    img {
                        width: 12vw;
                        height: 5vh;
                        border: 3px solid $primary-color;
                        border-radius: 6px;
                    }
                }
            }
        }
    }



    /* New Release */

@media only screen and (min-width: 992px) {
    .main-wrapper-release{
        width: 100%;
        height: 690px;
        display: grid;
        grid-template-areas: "viewbox sidebar";
        grid-template-columns: 730px 256px;
        grid-gap: 78px;
        margin: 13px 188px 116px 188px;
        .container-wrapper-release {
            //width: calc(100% - 360px);
            //margin-right: 360px;
            transition: all .3s ease;
            grid-area: viewbox; 
            .viewbox-container {
                height: 100%;
                background-color: #000;
                position: relative;
                .viewbox-text {
                    @include AmandaFont;
                    position: absolute;
                    //top: calc(50% + (36px + 56px));
                    top: calc(20% + (36px + 56px));
                    left: 10%;
                    // padding: 1rem;
                    //border: 3px solid black;
                    // font-size: 6.94vw;
                    display: flex;
                    align-items: center;
                    // text-align: center;
                    justify-content: center;
                    color: #FFFFFF;
                    // height: 110px;
                    // width: calc(100% - 360px);
                    z-index: 1;
                }
                .line-sun {
                    text-shadow: 0px 2px 13px #F2D03B;
                }
                .line-moon {
                    text-shadow: 0px 2px 13px #FFFFFF;
                }
                img {
                    //width: 100%;
                }
                .theme {
                    position: absolute;
                    //top: 70px;
                    left: 24px;
                    z-index: 1;
                    display: flex;
                    &>svg {
                        margin: 18px;
                        cursor: pointer;
                    }
                }
                .btn-theme {
                    position: absolute;
                    top: 9px;
                    left: 185px;
                    z-index: 1;
                    display: flex;
                    .btn {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #FFFFFF;
                        filter: drop-shadow(0px 0px 4px #FFFFFF);
                        opacity: 0.4;
                        font-size: 10px;
                        line-height: 12px;
                        text-align: center;
                        color: #000000;
                        outline: none;
                        cursor: pointer;
                    }
                    &>button {
                        margin: 7px
                    }
                    .btn-on {
                        background: #F2D03B;
                        opacity: 0.8;
                    }
                    .btn-off {
                        background: #FFFFFF;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .sidebar-wrapper-release{
        grid-area: sidebar;
        //background-color: green;
    }
}
    /* .container-wrapper-release{
        grid-area: viewbox; 
        //background-color: blue;
    } */


    /* Tablet device */
    @media only screen and (max-width: 768px) {

        .main-wrapper-release{
            width: 100%;
            height: 690px;
            display: grid;
            grid-template-areas: "viewbox" 
                                 "sidebar";
            grid-template-columns: 730px 380px;
            grid-gap: 78px;
            margin: 13px 188px 116px 188px;
            .container-wrapper-release {
                //width: calc(100% - 360px);
                //margin-right: 360px;
                transition: all .3s ease;
                grid-area: viewbox; 
                .viewbox-container {
                    height: 100%;
                    background-color: #000;
                    position: relative;
                    .viewbox-text {
                        @include AmandaFont;
                        position: absolute;
                        //top: calc(50% + (36px + 56px));
                        top: calc(20% + (36px + 56px));
                        left: 25%;
                        // padding: 1rem;
                        //border: 3px solid black;
                        // font-size: 6.94vw;
                        display: flex;
                        align-items: center;
                        // text-align: center;
                        justify-content: center;
                        color: #FFFFFF;
                        // height: 110px;
                        // width: calc(100% - 360px);
                        z-index: 1;
                    }
                    .line-sun {
                        text-shadow: 0px 2px 13px #F2D03B;
                    }
                    .line-moon {
                        text-shadow: 0px 2px 13px #FFFFFF;
                    }
                    img {
                        //width: 100%;
                    }
                    .theme {
                        position: absolute;
                        //top: 70px;
                        left: 24px;
                        z-index: 1;
                        display: flex;
                        &>svg {
                            margin: 18px;
                            cursor: pointer;
                        }
                    }
                    .btn-theme {
                        position: absolute;
                        top: 9px;
                        left: 185px;
                        z-index: 1;
                        display: flex;
                        .btn {
                            width: 24px;
                            height: 24px;
                            border: 1px solid #FFFFFF;
                            filter: drop-shadow(0px 0px 4px #FFFFFF);
                            opacity: 0.4;
                            font-size: 10px;
                            line-height: 12px;
                            text-align: center;
                            color: #000000;
                            outline: none;
                            cursor: pointer;
                        }
                        &>button {
                            margin: 7px
                        }
                        .btn-on {
                            background: #F2D03B;
                            opacity: 0.8;
                        }
                        .btn-off {
                            background: #FFFFFF;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
        .sidebar-wrapper-release{
            grid-area: sidebar;
            //background-color: green;
        }
    }
    @media only screen and (min-width: 1600px){
    .slideshow-container{
        width: 800px;
    }
    #sidebar{
        margin-left: 77px;
    }
    .tab-list-top{
        right: 190px !important;
    }
 }
    /* Phone devices */
    @media only screen and (max-width: 600px){

        .main-wrapper-release{
            /* width: 100%;
            height: 690px;
            display: grid;
            grid-template-areas: "viewbox" 
                                 "sidebar";
            grid-template-columns: 730px 380px;
            grid-gap: 78px;
            margin: 13px 188px 116px 188px; */
            width: 100%;
            height: 690px;
            display: grid;
            grid-template-areas:
                "viewbox"
                "sidebar";
            grid-template-columns: 730px 380px;
            grid-gap: 78px;
            margin: 18px;
            .container-wrapper-release {
                //width: calc(100% - 360px);
                //margin-right: 360px;
                transition: all .3s ease;
                grid-area: viewbox; 
                .viewbox-container {
                    height: 100%;
                    background-color: #000;
                    position: relative;
                    .viewbox-text {
                        @include AmandaFont;
                        position: absolute;
                        //top: calc(50% + (36px + 56px));
                        top: calc(20% + (36px + 56px));
                        left: 25%;
                        // padding: 1rem;
                        //border: 3px solid black;
                        // font-size: 6.94vw;
                        display: flex;
                        align-items: center;
                        // text-align: center;
                        justify-content: center;
                        color: #FFFFFF;
                        // height: 110px;
                        // width: calc(100% - 360px);
                        z-index: 1;
                    }
                    .line-sun {
                        text-shadow: 0px 2px 13px #F2D03B;
                    }
                    .line-moon {
                        text-shadow: 0px 2px 13px #FFFFFF;
                    }
                    img {
                        //width: 100%;
                    }
                    .theme {
                        position: absolute;
                        //top: 70px;
                        left: 24px;
                        z-index: 1;
                        display: flex;
                        &>svg {
                            margin: 18px;
                            cursor: pointer;
                        }
                    }
                    .btn-theme {
                        position: absolute;
                        top: 9px;
                        left: 185px;
                        z-index: 1;
                        display: flex;
                        .btn {
                            width: 24px;
                            height: 24px;
                            border: 1px solid #FFFFFF;
                            filter: drop-shadow(0px 0px 4px #FFFFFF);
                            opacity: 0.4;
                            font-size: 10px;
                            line-height: 12px;
                            text-align: center;
                            color: #000000;
                            outline: none;
                            cursor: pointer;
                        }
                        &>button {
                            margin: 7px
                        }
                        .btn-on {
                            background: #F2D03B;
                            opacity: 0.8;
                        }
                        .btn-off {
                            background: #FFFFFF;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
        .sidebar-wrapper-release{
            grid-area: sidebar;
            //background-color: green;
        }
    }


    .main-wrapper-release_tb{
        width: 100%;
       
        .container-wrapper-release_tb {
            //width: calc(100% - 360px);
            //margin-right: 360px;
            transition: all .3s ease;
            .viewbox-container {
                height: 100%;
                background-color: #000;
                position: relative;
                .viewbox-text {
                    @include AmandaFont;
                    position: absolute;
                    //top: calc(50% + (36px + 56px));
                    top: calc(20% + (36px + 56px));
                    left: 10%;
                    // padding: 1rem;
                    //border: 3px solid black;
                    // font-size: 6.94vw;
                    display: flex;
                    align-items: center;
                    // text-align: center;
                    justify-content: center;
                    color: #FFFFFF;
                    // height: 110px;
                    // width: calc(100% - 360px);
                    z-index: 1;
                }
                .line-sun {
                    text-shadow: 0px 2px 13px #F2D03B;
                }
                .line-moon {
                    text-shadow: 0px 2px 13px #FFFFFF;
                }
                img {
                    //width: 100%;
                }
                .theme {
                    position: absolute;
                    //top: 70px;
                    left: 24px;
                    z-index: 1;
                    display: flex;
                    &>svg {
                        margin: 18px;
                        cursor: pointer;
                    }
                }
                .btn-theme {
                    position: absolute;
                    top: 9px;
                    left: 185px;
                    z-index: 1;
                    display: flex;
                    .btn {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #FFFFFF;
                        filter: drop-shadow(0px 0px 4px #FFFFFF);
                        opacity: 0.4;
                        font-size: 10px;
                        line-height: 12px;
                        text-align: center;
                        color: #000000;
                        outline: none;
                        cursor: pointer;
                    }
                    &>button {
                        margin: 7px
                    }
                    .btn-on {
                        background: #F2D03B;
                        opacity: 0.8;
                    }
                    .btn-off {
                        background: #FFFFFF;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .sidebar-wrapper-release_tb{
        //grid-area: sidebar;
        //background-color: green;
    }

    .container-wrapper-release_tb{
        //padding: 0 52px;
        padding-left: 91px;
        padding-right: 52px;
    }

    .tb-range-size_card{
        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        //width: 365.87px;
         width: 519.87px;
         position: relative;
        .card{
            position: absolute;
            top: 205px !important;
            //left: 5px !important;
        }
    }

    .tb-configuration{
        margin-top: 110px;
        margin-bottom: 29px;
        .configuration{
            display: flex;
            justify-content: center;
            padding: 82px;
            &>div{
                margin-left: 55px;
            }
        }
        
    }
    @media only screen and (max-width: 768px){
        .tb-configuration{
            margin-top: 100px;
            .configuration{
                padding: 82px;
                &>div{
                    margin-left: 28px;
                }
            }
            
        }
        .main-wrapper-release_tb{
            width: 110%;
        }
        .container-wrapper-release_tb{
            //padding: 0 52px;
            padding-left: 48px;
            padding-right: 128px;
        }
    }

    .theme-container{
        display: flex;
        position: absolute;
        right: 280px;
    }




    /* Mobile */

    .main-wrapper-release_mb{
        width: 100%;
        .container-wrapper-release_mb {
            //width: calc(100% - 360px);
            //margin-right: 360px;
            transition: all .3s ease;
            .viewbox-container {
                height: 100%;
                background-color: #000;
                position: relative;
                .viewbox-text {
                    @include AmandaFont;
                    position: absolute;
                    //top: calc(50% + (36px + 56px));
                    top: calc(20% + (36px + 56px));
                    left: 10%;
                    // padding: 1rem;
                    //border: 3px solid black;
                    // font-size: 6.94vw;
                    display: flex;
                    align-items: center;
                    // text-align: center;
                    justify-content: center;
                    color: #FFFFFF;
                    // height: 110px;
                    // width: calc(100% - 360px);
                    z-index: 1;
                }
                .line-sun {
                    text-shadow: 0px 2px 13px #F2D03B;
                }
                .line-moon {
                    text-shadow: 0px 2px 13px #FFFFFF;
                }
                img {
                    //width: 100%;
                }
                .theme {
                    position: absolute;
                    //top: 70px;
                    left: 24px;
                    z-index: 1;
                    display: flex;
                    &>svg {
                        margin: 18px;
                        cursor: pointer;
                    }
                }
                .btn-theme {
                    position: absolute;
                    top: 9px;
                    left: 185px;
                    z-index: 1;
                    display: flex;
                    .btn {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #FFFFFF;
                        filter: drop-shadow(0px 0px 4px #FFFFFF);
                        opacity: 0.4;
                        font-size: 10px;
                        line-height: 12px;
                        text-align: center;
                        color: #000000;
                        outline: none;
                        cursor: pointer;
                    }
                    &>button {
                        margin: 7px
                    }
                    .btn-on {
                        background: #F2D03B;
                        opacity: 0.8;
                    }
                    .btn-off {
                        background: #FFFFFF;
                        opacity: 0.8;
                    }
                }
            }
        }


        .configuration{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 110px;
            width: 100%;
            margin-left: 26px;
        }
    }